import React from "react";

type DeleteModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  categoryName?: string;
};

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  categoryName,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
        <h2 className="text-lg font-medium text-center">Confirm Deletion</h2>
        <p className="mt-4 text-sm text-center">
          Are you sure you want to delete ?
        </p>
        <div className="flex justify-center gap-4 mt-6">
          <button
            className="px-6 py-2 text-sm text-white rounded-md bg-customOrange hover:bg-orange-700"
            onClick={onConfirm}
          >
            Delete
          </button>
          <button
            className="px-6 py-2 text-sm text-white bg-gray-500 rounded-md hover:bg-gray-600"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
