import { Navigate, Outlet } from 'react-router-dom';
import {  useSelector } from 'react-redux';
import type { RootState } from '../redux/store';

const ProtectedRoute = () => {
  const { token, isAdmin } = useSelector((state: RootState) => state.auth);


  if (!isAdmin) {
    return <Navigate to="/login" />;
  }

  return token && isAdmin ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
