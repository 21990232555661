import React, { useEffect, useState } from "react";
import CreateButton from "../CreateButton/CreateButton";
import CancelButton from "../CancelButton/CancelButton";
import { createRestaurant } from "../../redux/slices/allRestaurantsSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { DocumentUpload } from "iconsax-react";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AddRestaurantModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [openingHours, setOpeningHours] = useState("");
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("")
  const [image, setImage] = useState<File | null>(null);

  const [errors, setErrors] = useState({
    name: "",
    emailAddress: "",
    phoneNumber: "",
    ownerName: "",
    ownerEmail: "",
    openingHours: "",
    location: "",
    category: "",
    image: "",
  });

  const dispatch = useDispatch<AppDispatch>();
  const { loading, error } = useSelector((state: any) => state.restaurants);
  const { error: Error } = useSelector(
    (state: any) => state.allUsers
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    // Validate required fields
    let formIsValid = true;
    const newErrors: any = {};
  
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/; // Email regex pattern
  
    if (!name) {
      newErrors.name = "Restaurant Name cannot be empty";
      formIsValid = false;
    }
  
    if (!emailAddress) {
      newErrors.emailAddress = "Email Address cannot be empty";
      formIsValid = false;
    } else if (!emailPattern.test(emailAddress)) {
      newErrors.emailAddress = "Please enter a valid email address";
      formIsValid = false;
    }
  
    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone Number cannot be empty";
      formIsValid = false;
    }
  
    if (!ownerName) {
      newErrors.ownerName = "Owner's Name cannot be empty";
      formIsValid = false;
    }
  
    if (!ownerEmail) {
      newErrors.ownerEmail = "Owner's Email Address cannot be empty";
      formIsValid = false;
    } else if (!emailPattern.test(ownerEmail)) {
      newErrors.ownerEmail = "Please enter a valid email address";
      formIsValid = false;
    }
  
    if (!openingHours) {
      newErrors.openingHours = "Opening Hours cannot be empty";
      formIsValid = false;
    } else {
      // Opening Hours validation regex
      const openingHoursPattern = /^([01]?[0-9]|2[0-3]):([0-5][0-9])-([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
      if (!openingHoursPattern.test(openingHours)) {
        newErrors.openingHours = "Please enter valid opening hours (e.g., 09:00-23:00)";
        formIsValid = false;
      }
    }
  
    if (!location) {
      newErrors.location = "Location cannot be empty";
      formIsValid = false;
    }
  
    if (!image) {
      newErrors.image = "Restaurant Logo cannot be empty";
      formIsValid = false;
    }

    if (!category) {
      newErrors.category = "Category cannot be empty";
      formIsValid = false;
    }
  
    setErrors(newErrors);
  
    // If form is valid, dispatch the action
    if (formIsValid) {
      dispatch(
        createRestaurant({
          name,
          emailAddress,
          phoneNumber,
          ownerName,
          ownerEmail,
          openingHours,
          location,
          category,
          image,
        })
      );
  
      // After dispatch, reset the form
      setName("");
      setEmailAddress("");
      setPhoneNumber("");
      setOwnerName("");
      setOwnerEmail("");
      setOpeningHours("");
      setLocation("");
      setCategory("");
      setImage(null);
      onClose();
    }
  };
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      <div className="bg-white px-12 py-5 w-[1000px] relative">
        <h2 className="mb-4 text-xl font-medium">Add new restaurant</h2>
        <h2 className="mb-2 font-medium">Create new restaurant profile</h2>
        <form className="w-full mt-4" onSubmit={handleSubmit}>
          <div className="flex gap-4 mb-6">
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-medium">
                Restaurant Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="Name"
                className="border h-[40px] rounded-md pl-3 text-sm focus:border-2 focus:outline-none leading-[40px]"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, name: "" }));  // Clear error when user types
                }}
                
              />
              {errors.name && (
                <p className="text-xs text-red-600">{errors.name}</p>
              )}
            </div>
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-medium">
                Email Address <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="restaurant@gmail.com"
                className="border h-[40px] rounded-md pl-3 text-sm focus:border-2 focus:outline-none leading-[40px]"
                value={emailAddress}
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, emailAddress: "" }));  // Clear error when user types
                }}
              />
              {errors.emailAddress && (
                <p className="text-xs text-red-600">{errors.emailAddress}</p>
              )}
            </div>
          </div>

          <div className="flex gap-4 mb-6">
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-medium">
                Restaurant Logo <span className="text-red-600">*</span>
              </label>
              <label className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none cursor-pointer flex justify-between items-center">
                <input
                  type="file"
                  name="file_upload"
                  className="hidden"
                  onChange={(e) => {
                    const selectedFile = e.target.files ? e.target.files[0] : null;
                    setImage(selectedFile);  // Set the selected image
                    setErrors((prevErrors) => ({ ...prevErrors, image: "" }));  // Clear the error
                  }}
                />
                {/* Placeholder text that changes color when an image is uploaded */}
                <span
                  className={`flex items-center h-full text-sm ${
                    image ? "text-black" : "text-gray-400"
                  }`}
                >
                  {image ? image.name : "Ngarko imazhin"}
                </span>
                <div className="p-1 mr-3 bg-gray-100 rounded-full" ><DocumentUpload size="18" color="#F85417" variant="Outline"/></div>
              </label>

              {errors.image && (
                <p className="text-xs text-red-600">{errors.image}</p>
              )}
            </div>
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-medium">
                Opening Hours <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="00:00 AM-00:00 PM"
                className="border h-[40px] rounded-md pl-3 text-sm focus:border-2 focus:outline-none leading-[40px]"
                value={openingHours}
                onChange={(e) => {
                  setOpeningHours(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, openingHours: "" }));  // Clear error when user types
                }}
              />
              {errors.openingHours && (
                <p className="text-xs text-red-600">{errors.openingHours}</p>
              )}
            </div>
          </div>

          <div className="flex gap-4 mb-6">
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-medium">
                Owner’s Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="Full Name"
                className="border h-[40px] rounded-md pl-3 text-sm focus:border-2 focus:outline-none leading-[40px]"
                value={ownerName}
                onChange={(e) => {
                  setOwnerName(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, ownerName: "" }));  // Clear error when user types
                }}
              />
              {errors.ownerName && (
                <p className="text-xs text-red-600">{errors.ownerName}</p>
              )}
            </div>
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-medium">
                Owner’s email address <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="owner@gmail.com"
                className="border h-[40px] rounded-md pl-3 text-sm focus:border-2 focus:outline-none leading-[40px]"
                value={ownerEmail}
                onChange={(e) => {
                  setOwnerEmail(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, ownerEmail: "" }));  // Clear error when user types
                }}
              />
              {errors.ownerEmail && (
                <p className="text-xs text-red-600">{errors.ownerEmail}</p>
              )}
            </div>
          </div>

          <h2 className="mt-8 mb-2 font-medium">Restaurant Details</h2>
          <div className="flex gap-4 mt-4 mb-6">
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-medium">
                Location <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="Icon Tower - Prishtine"
                className="border h-[40px] rounded-md pl-3 text-sm focus:border-2 focus:outline-none leading-[40px]"
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, location: "" }));  // Clear error when user types
                }}
              />
              {errors.location && (
                <p className="text-xs text-red-600">{errors.location}</p>
              )}
            </div>
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-medium">
                Restaurant Category <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                placeholder="Tradicional"
                className="border h-[40px] rounded-md pl-3 text-sm focus:border-2 focus:outline-none leading-[40px]"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, category: "" }));  // Clear error when user types
                }}
              />
              {errors.category && (
                <p className="text-xs text-red-600">{errors.category}</p>
              )}
            </div>
            <div className="flex flex-col w-6/12 gap-1">
              <label className="text-xs font-medium">
                Phone Number <span className="text-red-600">*</span>
              </label>
              <input
                type="number"
                placeholder="+383 49 000 000"
                className="border h-[40px] rounded-md pl-3 text-sm focus:border-2 focus:outline-none leading-[40px]"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: "" }));  // Clear error when user types
                }}
              />
              {errors.phoneNumber && (
                <p className="text-xs text-red-600">{errors.phoneNumber}</p>
              )}
            </div>
          </div>

          <div className="flex justify-end gap-2 mt-8">
            <CreateButton disabled={loading} />
            <CancelButton onClick={onClose} />
          </div>
          {(error || Error) && (
            <p className="mt-2 text-red-600">
              {error && <span>{error} </span>}
              {Error && <span>{Error}</span>}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddRestaurantModal;
