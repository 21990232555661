import React from "react";
import { NavLink } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import Logo from "../../assets/Logo.png";

type Props = {};

const SidebarAdmin: React.FC = (props: Props) => {
  return (
    <div className="w-[240px] bg-sidebarcolor min-h-screen">
      <div className="flex flex-col items-center pt-4">
        <div className="w-full pl-10">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="flex flex-col items-center w-full pt-10">
          <NavLink
            to="/admin-panel/add-new-food"
            className={({ isActive }) =>
              isActive
                ? "bg-customOrange w-full text-white py-3 pl-10 text-sm"
                : "w-full text-white py-3 pl-10 text-sm"
            }
          >
            <div className="flex items-center gap-2">
              <RxDashboard />
              ADD NEW FOOD
            </div>
          </NavLink>
          <NavLink
            to="/admin-panel/allposts"
            className={({ isActive }) =>
              isActive
                ? "bg-customOrange w-full text-white py-3 pl-10 text-sm"
                : "w-full text-white py-3 pl-10 text-sm"
            }
          >
            <div className="flex items-center gap-2">
              <RxDashboard />
              ALL POSTS
            </div>
          </NavLink>
          <NavLink
            to="/admin-panel/review-request"
            className={({ isActive }) =>
              isActive
                ? "bg-customOrange w-full text-white py-3 pl-10 text-sm"
                : "w-full text-white py-3 pl-10 text-sm"
            }
          >
            <div className="flex items-center gap-2">
              <RxDashboard />
              REVIEW REQUEST
            </div>
          </NavLink>
          <NavLink
            to="/admin-panel/category"
            className={({ isActive }) =>
              isActive
                ? "bg-customOrange w-full text-white py-3 pl-10 text-sm"
                : "w-full text-white py-3 pl-10 text-sm"
            }
          >
            <div className="flex items-center gap-2">
              <RxDashboard />
              CATEGORY
            </div>
          </NavLink>
          <NavLink
            to="/admin-panel/allusers"
            className={({ isActive }) =>
              isActive
                ? "bg-customOrange w-full text-white py-3 pl-10 text-sm"
                : "w-full text-white py-3 pl-10 text-sm"
            }
          >
            <div className="flex items-center gap-2">
              <RxDashboard />
              ALL USERS
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default SidebarAdmin;
