import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Fetch categories with pagination
export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async ({ page, size }: { page: number; size: number }, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.get(
        "https://dev.api.jomuntu.com/api/v1/categories",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { page, size },
        }
      );

      return {
        categories: response.data.content.map((category: any) => ({
          ...category,
          id: Number(category.id),
        })),
        totalElements: response.data.totalElements,
      };
    } catch (error: any) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

// Create a new category
export const createCategory = createAsyncThunk(
  "categories/createCategory",
  async (
    { categoryName, page, size }: { categoryName: string; page: number; size: number },
    { rejectWithValue, dispatch }
  ) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.post(
        "https://dev.api.jomuntu.com/api/v1/categories",
        { name: categoryName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Refetch categories after creation
      dispatch(fetchCategories({ page, size }));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to create category");
    }
  }
);

// Update an existing category
export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async (
    { categoryId, name, page, size }: { categoryId: number; name: string; page: number; size: number },
    { rejectWithValue, dispatch }
  ) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.put(
        `https://dev.api.jomuntu.com/api/v1/categories/${categoryId}`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Refetch categories after update
      dispatch(fetchCategories({ page, size }));
      return { id: categoryId, name: response.data.name };
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to update category");
    }
  }
);

// Delete a category
export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (categoryId: number, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      await axios.delete(`https://dev.api.jomuntu.com/api/v1/categories/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return categoryId;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to delete category");
    }
  }
);
