import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateFoodWithImage } from "../../redux/slices/addNewFoodSlice";
import { Category } from "../../redux/slices/categorySlice";
import axios from "axios";
import { IoIosArrowDown } from "react-icons/io";
import { AppDispatch } from "../../redux/store";
import { fetchMyFoodItems } from "../../redux/slices/myFoodItems";

type EditFoodItemProps = {
  item: any; 
  onCancel: () => void;
  onSave: () => void
};

const EditFoodItem: React.FC<EditFoodItemProps> = ({ item, onCancel, onSave }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [formData, setFormData] = useState({ ...item });
  const [categories, setCategories] = useState<Category[]>([]);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
  const [imagePreview, setImagePreview] = useState<string | null>(formData.imageUrl);
  const [image, setImage] = useState<File | null>(null);
  const [categoriesId, setCategoriesId] = useState<number[]>()

  const toggleCategoryDropdown = () => setIsCategoryOpen(!isCategoryOpen);

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const categoriesResponse = await axios.get(
          "https://dev.api.jomuntu.com/api/v1/categories?size=100",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const fetchedCategories = categoriesResponse.data.content;
      setCategories(fetchedCategories);
      setCategoriesId(fetchedCategories.map((category: Category) => category.id));

      // Match category names in formData with fetched categories and set selected IDs
      const matchingIds = fetchedCategories
        .filter((category: Category) =>
          formData.categories?.includes(category.name)
        )
        .map((category: Category) => category.id);

      setSelectedCategory(matchingIds);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }
    fetchData();
  }, [item, formData.categories]);

  const handleCategorySelect = (id: number) => {
    setSelectedCategory([id]); // Replace the current selection with the clicked category ID
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onload = () => setImagePreview(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    const updatedData = {
      ...formData,
      categoryIds: selectedCategory.length > 0 ? selectedCategory : categoriesId,
    };

    // Dispatch the Redux action
    dispatch(updateFoodWithImage(formData.id, updatedData, image));
    dispatch(fetchMyFoodItems())
    dispatch(onSave)
  };

  return (
    <div>
      <h2 className="mt-10 text-2xl font-medium">Edito postin</h2>
      <p>Pas editimit te imazhit ose titullit ju duhet te dergoni kete post perseri per konfirmim.</p>
      <form className="pt-8">
        <div className="pb-4">
          <label className="text-sm font-medium">Imazhi i ushqimit:</label>
          <div className="pt-2">
            <input type="file" name="image" onChange={handleImageChange} />
            {imagePreview && <img src={imagePreview} className="h-48" alt="Preview" />}
          </div>
        </div>
        <div className="flex gap-3">
          <div className="w-4/12">
            <label className="text-sm font-medium">Emri i Menus:</label>
            <input
              type="text"
              name="foodName"
              value={formData.foodName}
              className="w-full py-2 pl-4 text-sm border-b outline-none"
              onChange={handleInputChange}
            />
          </div>
          <div className="w-4/12">
            <label className="text-sm font-medium">Kategoria:</label>
            <div className="relative" onClick={toggleCategoryDropdown}>
              <div className="border-b h-[38px] rounded-md pl-4 text-sm flex items-center justify-between cursor-pointer">
                <span>
                  {selectedCategory.length > 0
                    ? selectedCategory
                        .map(
                          (id) =>
                            categories.find((category) => category.id === id)?.name
                        )
                        .join(", ")
                    : formData.categories}
                </span>
                <div className="pr-3">
                  <IoIosArrowDown color="gray" />
                </div>
              </div>
              {isCategoryOpen && (
                <div className="absolute z-10 w-full mt-1 overflow-y-auto bg-white border rounded-md shadow-lg max-h-60">
                  {categories.map((category) => (
                    <div
                      key={category.id}
                      onClick={() => handleCategorySelect(category.id)}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      {category.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="w-4/12">
            <label className="text-sm font-medium">Qmimi:</label>
            <input
              type="text"
              name="price"
              value={formData.price}
              className="w-full py-2 pl-4 text-sm border-b outline-none"
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="flex justify-end gap-2 pt-20">
          <button
            type="button"
            onClick={handleSave}
            className="px-6 py-2 text-sm text-white rounded-lg bg-customOrange"
          >
            RUAJ NDRYSHIMET
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="px-6 py-2 text-sm border rounded-lg"
          >
            ANULO
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditFoodItem;
