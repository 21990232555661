import { toast } from 'react-toastify';

// Define a reusable configuration
export const toastSuccess = (message: string) => {
  toast.success(message, {
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: {
      fontFamily: "Clash Grotesk", // Custom font
      fontSize: "16px",
    },
  });
};

export const toastError = (message: string) => {
  toast.error(message, {
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: {
      fontFamily: "Clash Grotesk", // Custom font
      fontSize: "16px",
    },
  });
};
