import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { updateOfferWithImage } from "../../redux/slices/addNewDailyOffer";
import { fetchMyDailyOffers } from "../../redux/slices/myDailyOffers";

type EditDailyOfferProps = {
  offer: any; 
  onCancel: () => void;
  onSave: () => void
};

const EditDailyOffer: React.FC<EditDailyOfferProps> = ({ offer, onCancel, onSave }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [formData, setFormData] = useState({ ...offer });
  const [imagePreview, setImagePreview] = useState<string | null>(formData.imageUrl);
  const [image, setImage] = useState<File | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onload = () => setImagePreview(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    const updatedOfferData = {
      ...formData,
    };

    // Dispatch the Redux action
    dispatch(updateOfferWithImage(formData.id, updatedOfferData, image));
    dispatch(fetchMyDailyOffers())
    dispatch(onSave)
    dispatch(fetchMyDailyOffers())
  };

  return (
    <div>
      <h2 className="mt-10 text-2xl font-medium">Edito oferten</h2>
      <p>Pas editimit te imazhit ose titullit ju duhet te dergoni kete ofert perseri per konfirmim.</p>
      <form className="pt-8">
        <div className="pb-4">
          <label className="text-sm font-medium">Imazhi i ofertes:</label>
          <div className="pt-2">
            <input type="file" name="image" onChange={handleImageChange} />
            {imagePreview && <img src={imagePreview} className="h-48" alt="Preview" />}
          </div>
        </div>
        <div className="flex gap-3">
          <div className="w-4/12">
            <label className="text-sm font-medium">Emri i Menus:</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              className="w-full py-2 pl-4 text-sm border-b outline-none"
              onChange={handleInputChange}
            />
          </div>
          <div className="w-4/12">
            <label className="text-sm font-medium">Qmimi:</label>
            <input
              type="text"
              name="price"
              value={formData.price}
              className="w-full py-2 pl-4 text-sm border-b outline-none"
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="flex justify-end gap-2 pt-20">
          <button
            type="button"
            onClick={handleSave}
            className="px-6 py-2 text-sm text-white rounded-lg bg-customOrange"
          >
            RUAJ NDRYSHIMET
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="px-6 py-2 text-sm border rounded-lg"
          >
            ANULO
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditDailyOffer;
