import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL_RESTAURANTS = "https://dev.api.jomuntu.com/api/v1/food-venues";

interface Restaurant {
  createdAt: any;
  id: number;
  name: string;
  emailAddress: string;
  phoneNumber: string;
  adminName: string;
  ownerName: string;
  ownerEmail: string;
  openingHours: string;
  location: string;
  category: string;
  logoUrl: string;
}

// interface Pageable {
//   pageNumber: number;
//   pageSize: number;
//   totalPages: number;
//   totalElements: number;
// }

// interface FetchRestaurantsResponse {
//   content: Restaurant[];
//   pageable: Pageable;
//   totalPages: number;
//   totalElements: number;
// }

interface RestaurantsState {
  restaurants: Restaurant[];
  loading: boolean;
  error: string | null;
  page: number;
  size: number;
  totalPages: number;
  totalElements: number;
}

const initialState: RestaurantsState = {
  restaurants: [],
  loading: false,
  error: null,
  page: 0,
  size: 10,
  totalPages: 0,
  totalElements: 0,
};

export const fetchRestaurants = createAsyncThunk(
  "restaurants/fetchRestaurants",
  async (
    { page, size }: { page: number; size: number },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(API_URL_RESTAURANTS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch restaurants");
    }
  }
);

export const deleteRestaurant = createAsyncThunk(
  "allUsers/deleteRestaurant",
  async (id: number, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${API_URL_RESTAURANTS}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return id;
    } catch (error) {
      return rejectWithValue("Failed to delete restaurant");
    }
  }
);

export const createRestaurant = createAsyncThunk(
  "restaurants/createRestaurant",
  async (
    {
      name,
      emailAddress,
      phoneNumber,
      ownerName,
      ownerEmail,
      openingHours,
      location,
      category,
      image,
    }: {
      name: string;
      emailAddress: string;
      phoneNumber: string;
      ownerName: string;
      ownerEmail: string;
      openingHours: string;
      location: string;
      category: string
      image: File | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        return rejectWithValue("Token not found, please log in.");
      }

      // Step 1: Create the restaurant
      const response = await axios.post(
        API_URL_RESTAURANTS,
        {
          name,
          emailAddress,
          phoneNumber,
          adminName: "Admin",
          ownerName,
          ownerEmail,
          openingHours,
          location,
          category
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const restaurantId = response.data;

      // Step 2: Upload the image if present
      if (image) {
        const formData = new FormData();
        formData.append("image", image);

        await axios.post(
          `${API_URL_RESTAURANTS}/${restaurantId}/upload-image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      return restaurantId; // Return the restaurant ID after creation
    } catch (err: any) {
      return rejectWithValue(
        err.response?.data?.message || "Failed to create restaurant."
      );
    }
  }
);

const restaurantsSlice = createSlice({
  name: "restaurants",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRestaurants.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRestaurants.fulfilled, (state, action) => {
        state.loading = false;
        state.restaurants = action.payload.content;
        state.totalPages = action.payload.totalPages;
        state.totalElements = action.payload.totalElements;
      })
      .addCase(fetchRestaurants.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteRestaurant.fulfilled, (state, action) => {
        state.restaurants = state.restaurants.filter(
          (restaurant) => restaurant.id !== action.payload
        );
      })
      .addCase(deleteRestaurant.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(createRestaurant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createRestaurant.fulfilled, (state, action) => {
        state.loading = false;
        // Instead of adding an empty object, push the full restaurant object.
        const newRestaurant = action.payload; // Assuming the response contains the full restaurant object.
        state.restaurants.push(newRestaurant);
      })
      .addCase(createRestaurant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setPage } = restaurantsSlice.actions;

export default restaurantsSlice.reducer;
