import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface FoodItem {
  id: number;
  imageUrl: string;
  foodName: string;
  restaurantName: string;
  categories: string[];
  price: number;
  contactNumber: string;
  location: string;
  message: string | null;
  status: string;
}


interface FoodItemState {
  foodItems: FoodItem[];
  totalPages: number;
  page: number;
  loadingFood: boolean;
  error: string | null;
}

const initialState: FoodItemState = {
  foodItems: [],
  totalPages: 0,
  page: 0,
  loadingFood: false,
  error: null,
};

export const fetchMyFoodItems = () => async (dispatch: any) => {
  dispatch(setLoading());
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`https://dev.api.jomuntu.com/api/v1/food-items/my-items`, {
        
        headers: {
            Authorization: `Bearer ${token}`,
          },
      params: {
        size: 6,
      },
    });
    dispatch(setMyFoodItems(response.data));
  } catch (error: any) {
    dispatch(setError(error.response?.data?.message || "Failed to fetch posts"));
  }
};



const myFoodItemsSlice = createSlice({
  name: "myFoodItems",
  initialState,
  reducers: {
    setLoading(state) {
      state.loadingFood = true;
      state.error = null;
    },
    setMyFoodItems(state, action) {
      state.loadingFood = false;
      state.foodItems = action.payload.content;
    },
    setError(state, action) {
      state.loadingFood = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setError, setMyFoodItems } = myFoodItemsSlice.actions;

export default myFoodItemsSlice.reducer;
