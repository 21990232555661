// authAdminSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { login, fetchCurrentUser, refreshToken } from "../actions/authActions";

interface AuthState {
  token: string | null;
  user: { name: string; role: string } | null;
  loading: boolean;
  error: string | null;
  emailError: string | null;
  passwordError: string | null;
  isAdmin: boolean;
  isRestaurant: boolean;
}

const initialState: AuthState = {
  token: localStorage.getItem("token"),
  user: JSON.parse(localStorage.getItem("user") || "null"),
  loading: false,
  error: null,
  emailError: null,
  passwordError: null,
  isAdmin: JSON.parse(localStorage.getItem("user") || "null")?.role === "ADMIN",
  isRestaurant: JSON.parse(localStorage.getItem("user") || "null")?.role === "RESTAURANT_ADMIN",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.user = null;
      state.emailError = null;
      state.passwordError = null;
      state.isAdmin = false;
      state.isRestaurant = false;
      localStorage.removeItem("token");
      localStorage.removeItem("expiration");
      localStorage.removeItem("user");
      localStorage.removeItem("refreshToken");
    },
    setEmailError: (state, action) => {
      state.emailError = action.payload;
    },
    setPasswordError: (state, action) => {
      state.passwordError = action.payload;
    },
    clearErrors: (state) => {
      state.error = null;
      state.emailError = null;
      state.passwordError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isAdmin = action.payload.role === "ADMIN";
        state.isRestaurant = action.payload.role === "RESTAURANT_ADMIN";
        localStorage.setItem("user", JSON.stringify(action.payload)); 
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(refreshToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

export const { logout, setEmailError, setPasswordError, clearErrors } = authSlice.actions;
export default authSlice.reducer;
