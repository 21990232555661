import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface MyDailyOffer {
  id: number;
  title: string;
  price: number;
  imageUrl: string;
  venueId: number;
  contactNumber: string;
  location: string;
  restaurantName: string;
  status: string;
}

interface MyDailyOfferState {
  data: MyDailyOffer[];
  loading: boolean;
  error: string | null;
}

const initialState: MyDailyOfferState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchMyDailyOffers = () => async (dispatch: any) => {
  dispatch(setLoading());
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `https://dev.api.jomuntu.com/api/v1/daily-offers/my-daily-offers`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(setMyDailyOffers(response.data)); 
  } catch (error: any) {
    dispatch(
      setError(error.response?.data?.message || "Failed to fetch daily offers")
    );
  }
};

const myDailyOffersSlice = createSlice({
  name: "myDailyOffers",
  initialState,
  reducers: {
    setLoading(state) {
      state.loading = true;
      state.error = null;
    },
    setMyDailyOffers(state, action) {
      state.loading = false;
      state.data = action.payload; 
    },
    setError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setError, setMyDailyOffers } =
  myDailyOffersSlice.actions;

export default myDailyOffersSlice.reducer;
