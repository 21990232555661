import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toastSuccess } from "../../utils/Toast/Toast";

interface FoodItem {
  id: number;
  imageUrl: string;
  foodName: string;
  restaurantName: string;
  categories: string[];
  price: number;
  contactNumber: string;
  location: string;
  message: string | null;
  status: string;
}

interface DailyOffer {
  id: number;
  title: string;
  price: number;
  imageUrl: string;
  restaurantName: string;
  location: string;
  contactNumber: string;
  startTime: string;
  endTime: string;
  venueId: number;
  foodItems: FoodItem[];
  status: string;
}

interface DailyOfferState {
  data: DailyOffer[];
  totalPages: number;
  page: number;
  loading: boolean;
  error: string | null;
}

const initialState: DailyOfferState = {
  data: [],
  totalPages: 0,
  page: 0,
  loading: false,
  error: null,
};

export const fetchDailyOffers = (page: number) => async (dispatch: any) => {
  dispatch(setLoading());
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`https://dev.api.jomuntu.com/api/v1/daily-offers/review/pending`, {
        
        headers: {
            Authorization: `Bearer ${token}`,
          },
      params: {
        page,
        size: 6,
      },
    });
    dispatch(setDailyOffer({ ...response.data, page }));
  } catch (error: any) {
    dispatch(setError(error.response?.data?.message || "Failed to fetch posts"));
  }
};

export const approveDailyOffer = (dailyOfferId: number) => async (dispatch: any) => {
  dispatch(setLoading());
  try {
    const token = localStorage.getItem('token');
    await axios.put(
      `https://dev.api.jomuntu.com/api/v1/daily-offers/approve/${dailyOfferId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(updateOfferStatus(dailyOfferId)); // Update the offer's status locally after approval
    dispatch(toastSuccess("Oferta eshte aprovuar me sukses!"))
  } catch (error: any) {
    dispatch(setError(error.response?.data?.message));
  }
};

export const declineDailyOffer = (dailyOfferId: number) => async (dispatch: any) => {
  dispatch(setLoading());
  try {
    const token = localStorage.getItem('token');
    await axios.put(
      `https://dev.api.jomuntu.com/api/v1/daily-offers/decline/${dailyOfferId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(declineOfferStatus(dailyOfferId)); // Update the offer's status locally after approval
    dispatch(toastSuccess("Oferta eshte refuzuar me sukses!"))
  } catch (error: any) {
    dispatch(setError(error.response?.data?.message));
  }
};

const dailyOfferSlice = createSlice({
  name: "dailyOffer",
  initialState,
  reducers: {
    setLoading(state) {
      state.loading = true;
      state.error = null;
    },
    setError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setDailyOffer(state, action) {
      state.loading = false;
      state.data = action.payload.content;
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.page;
    },
    resetPage(state) {
      state.page = 0;
    },
    updateOfferStatus(state, action) {
      const offerId = action.payload;
      const updatedOffer = state.data.find((offer) => offer.id === offerId);
      if (updatedOffer) {
        updatedOffer.status = 'APPROVED'; 
      }
      state.loading = false
    },
    declineOfferStatus(state, action) {
      const offerId = action.payload;
      const updatedOffer = state.data.find((offer) => offer.id === offerId);
      if (updatedOffer) {
        updatedOffer.status = 'DECLINED'; 
      }
      state.loading = false
    },
  },
});

export const { setLoading, setError, setDailyOffer, resetPage, updateOfferStatus, declineOfferStatus } = dailyOfferSlice.actions;

export default dailyOfferSlice.reducer;
