import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { fetchMyDailyOffers } from "./myDailyOffers";
import { toastSuccess } from "../../utils/Toast/Toast";

// API URL for creating food and uploading image
const API_URL_DAILY_OFFERS = "https://dev.api.jomuntu.com/api/v1/daily-offers";
const API_URL_DAILY_OFFER_IMAGE =
  "https://dev.api.jomuntu.com/api/v1/daily-offers/{dailyOfferId}/upload-image";
const API_URL_UPDATE_DAILY_OFFER = "https://dev.api.jomuntu.com/api/v1/daily-offers/{dailyOfferId}"
const API_URL_UPDATE_DAILY_OFFER_IMAGE = "https://dev.api.jomuntu.com/api/v1/daily-offers/{dailyOfferId}/update-image"
interface AddNewFoodState {
  offerId: number | null;
  loading: boolean;
  error: string | null;
}

const initialState: AddNewFoodState = {
  offerId: null,
  loading: false,
  error: null,
};

// Create food and upload image
export const createDailyOffer =
  (
    offerData: {
      title: string;
      price: string | number;
    },
    image: File | null
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));

      const token = localStorage.getItem("token");

      const offerResponse = await axios.post(API_URL_DAILY_OFFERS, offerData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const dailyOfferId  = offerResponse.data;

      // Step 2: Upload the image if it's present
      if (image) {
        const formData = new FormData();
        formData.append("image", image);

        await axios.post(
          API_URL_DAILY_OFFER_IMAGE.replace("{dailyOfferId}", dailyOfferId.toString()),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      dispatch(setOfferId(dailyOfferId));
      dispatch(setLoading(false));
      dispatch(toastSuccess("Oferta u krijua me sukses!"))

    } catch (error: any) {
      dispatch(setError(error.response?.data));
      dispatch(setLoading(false));
    }
  };

  // Delete daily offer
export const deleteDailyOffer =
(dailyOfferId: number) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const token = localStorage.getItem("token");

    await axios.delete(`${API_URL_DAILY_OFFERS}/${dailyOfferId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(setLoading(false));
  } catch (error: any) {
    dispatch(setError(error.response?.data || "Error occurred while deleting offer"));
    dispatch(setLoading(false));
  }
};

export const updateOfferWithImage =
  (
    dailyOfferId : number,
    updatedOfferData: {
      title: string;
      price: string | number;
    },
    image: File | null
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));

      const token = localStorage.getItem("token");

      // Step 1: Update the food item
      await axios.put(
        API_URL_UPDATE_DAILY_OFFER.replace("{dailyOfferId}", dailyOfferId.toString()),
        updatedOfferData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Step 2: Upload the image if it's present
      if (image) {
        const formData = new FormData();
        formData.append("image", image);

        await axios.put(
          API_URL_UPDATE_DAILY_OFFER_IMAGE.replace("{dailyOfferId}", dailyOfferId.toString()),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      dispatch(fetchMyDailyOffers())
      dispatch(setOfferId(dailyOfferId));
      dispatch(setLoading(false));
      dispatch(toastSuccess("Oferta u editua me sukses!"))
    } catch (error: any) {
      dispatch(
        setError(
          error.response?.data.message || "Error occurred while updating food"
        )
      );
      dispatch(setLoading(false));
    }
  };

const addDailyOfferSlice = createSlice({
  name: "addDailyOffer",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOfferId: (state, action) => {
      state.offerId = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setOfferId, setError } = addDailyOfferSlice.actions;

export default addDailyOfferSlice.reducer;
