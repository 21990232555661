import React, { useState, useEffect } from "react";
import { GoBell } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/authAdminSlice";
import {fetchCurrentUser} from "../../redux/actions/authActions"
import type { RootState, AppDispatch } from "../../redux/store";

const AdminHeader = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>(); 
  const user = useSelector((state: RootState) => state.auth.user);
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    if (token && !user) {
      dispatch(fetchCurrentUser()); 
    }
  }, [dispatch, token, user]);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    setIsDropdownOpen(false);
  };

  return (
    <div className="flex gap-3 pt-2 pr-12">
      <button className="border w-[40px] h-[40px] flex justify-center items-center rounded-lg border-gray-200 hover:bg-gray-50">
        <GoBell />
      </button>
      <div>
        <div className="relative inline-block text-left">
          <button
            type="button"
            className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-3 h-[40px] text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
            onClick={handleDropdownToggle}
          >
            {user ? user.name : ""} {/* Display user name */}
            <svg className="w-5 h-5 -mr-1 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 z-10 w-32 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
              <button className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100" onClick={handleLogout}>
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
