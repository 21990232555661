import React, { useEffect, useState } from "react";
import CreateButton from "../../components/CreateButton/CreateButton";
import CancelButton from "../../components/CancelButton/CancelButton";
import axios from "axios";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { createFoodWithImage } from "../../redux/slices/addNewFoodSlice";
import { IoIosArrowDown } from "react-icons/io";
import { DocumentUpload } from "iconsax-react";
import { ToastContainer } from "react-toastify";

type Props = {};

interface Restaurant {
  id: number;
  name: string;
  phoneNumber: string;
  location: string;
}

interface Category {
  id: number;
  name: string;
}

const AddNewFood: React.FC = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState<number | null>(
    null
  );
  const [selectedCategory, setSelectedCategory] = useState<number[]>([]);

  const [foodName, setFoodName] = useState<string>("");
  const [price, setPrice] = useState<number | string>("");
  const [image, setImage] = useState<File | null>(null);

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [imageName, setImageName] = useState<string>("");

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const categoriesResponse = await axios.get(
          "https://dev.api.jomuntu.com/api/v1/categories?size=100",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const restaurantResponse = await axios.get(
          "https://dev.api.jomuntu.com/api/v1/food-venues?size=100"
        );

        setCategories(categoriesResponse.data.content);
        setRestaurants(restaurantResponse.data.content);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleCategoryDropdown = () => setIsCategoryOpen(!isCategoryOpen);

  const handleSelect = (id: number) => {
    const selectedRestaurant = restaurants.find(
      (restaurant) => restaurant.id === id
    );
    if (selectedRestaurant) {
      setSelectedRestaurant(id);
      setPhoneNumber(selectedRestaurant.phoneNumber);
      setLocation(selectedRestaurant.location);
    }
    setIsOpen(false);
  };
  const handleCategorySelect = (id: number) => {
    setSelectedCategory(
      (prevCategories) =>
        prevCategories.includes(id)
          ? prevCategories.filter((categoryId) => categoryId !== id)
          : [...prevCategories, id] // Add if not selected
    );
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedImage = e.target.files[0];
      setImage(selectedImage);
      setImageName(selectedImage.name);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const foodData = {
        foodName,
        price,
        venueId: selectedRestaurant,
        categoryIds: selectedCategory,
      };

      await dispatch(createFoodWithImage(foodData, image));

      setFoodName("");
      setPrice("");
      setSelectedRestaurant(null);
      setSelectedCategory([]);
      setImage(null);
      setLocation("");
      setPhoneNumber("");
    } catch (error) {
      console.error("Error creating food item:", error);
    }
  };

  const handleCancel = () => {
    setFoodName("");
    setPrice("");
    setSelectedRestaurant(null);
    setSelectedCategory([]);
    setImage(null);
    setLocation("");
    setPhoneNumber("");
  };
  return (
    <div className="flex w-full">
      <ToastContainer/>
      <div className="flex flex-col items-center flex-grow bg-white">
        <div className="w-full ">
          {/* ADD NEW FOOD */}
          <form onSubmit={handleSubmit}>
            <div className="pt-8">
              <h1 className="text-2xl font-medium">Add new food</h1>
              <p className="text-sm">
                In this section you can create a new food for the app
              </p>
            </div>
            <div className="flex flex-col gap-1 pt-6">
              <label className="text-sm font-medium">
                Food image <span className="text-red-600">*</span>
              </label>
              <label className="w-full border-2 h-[124px] rounded-md border-dashed border-borderColor flex flex-col justify-center items-center cursor-pointer hover:border-customOrange">
                <div className="flex justify-center p-2 rounded-full bg-gray-50">
                  <DocumentUpload color="#F85417" />
                </div>
                <h1>
                  <span className="font-medium text-customOrange">
                    Click To Upload
                  </span>{" "}
                  or drag and drop
                </h1>
                <h1 className="text-sm">(Max.File size: 25 MB)</h1>
                <input
                  type="file"
                  onChange={handleImageChange}
                  name="file_upload"
                  className="hidden"
                  id="image"
                />
                {image && (
                  <p className="mt-2 text-sm text-gray-500">{`Selected image: ${imageName}`}</p>
                )}
              </label>
            </div>
            <div className="flex w-full gap-4 pt-6">
              <div className="flex flex-col w-4/12 gap-1">
                <label className="text-sm font-medium">
                  Restaurant Name <span className="text-red-600">*</span>
                </label>
                <div className="relative" onClick={toggleDropdown}>
                  <div className="border h-[40px] rounded-md pl-4 text-sm flex items-center justify-between cursor-pointer">
                    <span>
                      {selectedRestaurant
                        ? restaurants.find(
                            (restaurant) => restaurant.id === selectedRestaurant
                          )?.name
                        : "Select Restaurant"}
                    </span>
                    <div className="pr-3">
                      <IoIosArrowDown color="gray" />
                    </div>
                  </div>
                  {isOpen && (
                    <div className="absolute z-10 w-full mt-1 overflow-y-auto bg-white border rounded-md shadow-lg max-h-60">
                      {restaurants.map((restaurant) => (
                        <div
                          key={restaurant.id}
                          className="p-2 cursor-pointer hover:bg-gray-100"
                          onClick={() => handleSelect(restaurant.id)}
                        >
                          {restaurant.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col w-4/12 gap-1">
                <label className="text-sm font-medium">
                  Food Name <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  value={foodName}
                  onChange={(e) => setFoodName(e.target.value)}
                  placeholder="Food Name"
                  className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none leading-[40px]"
                />
              </div>
              <div className="flex flex-col w-4/12 gap-1">
                <label className="text-sm font-medium">
                  Select Category <span className="text-red-600">*</span>
                </label>
                <div className="relative" onClick={toggleCategoryDropdown}>
                  <div className="border h-[40px] rounded-md pl-4 text-sm flex items-center justify-between cursor-pointer">
                    <span>
                      {selectedCategory.length > 0
                        ? selectedCategory
                            .map(
                              (id) =>
                                categories.find(
                                  (category) => category.id === id
                                )?.name
                            )
                            .join(", ")
                        : "Select Category"}
                    </span>
                    <div className="pr-3">
                      <IoIosArrowDown color="gray" />
                    </div>
                  </div>
                  {isCategoryOpen && (
                    <div className="absolute z-10 w-full mt-1 overflow-y-auto bg-white border rounded-md shadow-lg max-h-60">
                      {categories.map((category) => (
                        <div
                          key={category.id}
                          onClick={() => handleCategorySelect(category.id)}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                        >
                          {category.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* PROVIDER DETAILS */}
            <div className="pt-8">
              <div>
                <h1 className="text-2xl font-medium">Provider Details</h1>
              </div>
              <div className="flex w-full gap-4 pt-4 ">
                <div className="flex flex-col w-4/12 gap-1">
                  <label className="text-sm font-medium">
                    Phone Number <span className="text-red-600">*</span>
                  </label>
                  <input
                    readOnly
                    type="number"
                    value={phoneNumber}
                    placeholder="+44 20 7123 4567"
                    className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none leading-[44px]"
                  />
                </div>

                <div className="flex flex-col w-4/12 gap-1">
                  <label className="text-sm font-medium">
                    Price <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Price"
                    className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none leading-[44px]"
                  />
                </div>
                <div className="flex flex-col w-4/12 gap-1">
                  <label className="text-sm font-medium">
                    Location <span className="text-red-600">*</span>
                  </label>
                  <input
                    readOnly
                    type="text"
                    value={location}
                    placeholder="Location"
                    className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none leading-[44px]"
                  />
                </div>
              </div>
              <div className="flex justify-end gap-4 mt-28">
                <CreateButton />
                <CancelButton onClick={handleCancel} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewFood;
