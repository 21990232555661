import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { fetchMyFoodItems } from "./myFoodItems";
import { toastSuccess } from "../../utils/Toast/Toast";

// API URL for creating food and uploading image
const API_URL_FOOD_ITEMS = "https://dev.api.jomuntu.com/api/v1/food-items";
const API_URL_FOOD_ITEM_IMAGE =
  "https://dev.api.jomuntu.com/api/v1/food-items/{id}/upload-image";
const API_URL_UPDATE_FOOD_ITEM =
  "https://dev.api.jomuntu.com/api/v1/food-items/venue-admin/{id}";
const API_URL_UPDATE_FOOD_ITEM_IMAGE =
  "https://dev.api.jomuntu.com/api/v1/food-items/{id}/update-image-venue";

interface AddNewFoodState {
  foodId: number | null;
  loading: boolean;
  error: string | null;
}

const initialState: AddNewFoodState = {
  foodId: null,
  loading: false,
  error: null,
};

// Create food and upload image
export const createFoodWithImage =
  (
    foodData: {
      foodName: string;
      price: string | number;
      venueId?: number | null;
      categoryIds: number[] | null;
    },
    image: File | null
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));

      const token = localStorage.getItem("token");

      const foodResponse = await axios.post(API_URL_FOOD_ITEMS, foodData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const foodId = foodResponse.data;

      // Step 2: Upload the image if it's present
      if (image) {
        const formData = new FormData();
        formData.append("image", image);

        await axios.post(
          API_URL_FOOD_ITEM_IMAGE.replace("{id}", foodId.toString()),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      dispatch(fetchMyFoodItems())
      dispatch(setFoodId(foodId));
      dispatch(setLoading(false));
      dispatch(toastSuccess("Ushqimi u krijua me sukses!"))
    } catch (error: any) {
      dispatch(setError(error.response?.data.message));
      dispatch(setLoading(false));
    }
  };

// Update food and upload image
export const updateFoodWithImage =
  (
    foodId: number,
    foodData: {
      foodName: string;
      price: string | number;
      venueId?: number | null;
      categoryIds: number[] | null;
    },
    image: File | null
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));

      const token = localStorage.getItem("token");

      // Step 1: Update the food item
      await axios.post(
        API_URL_UPDATE_FOOD_ITEM.replace("{id}", foodId.toString()),
        foodData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Step 2: Upload the image if it's present
      if (image) {
        const formData = new FormData();
        formData.append("image", image);

        await axios.put(
          API_URL_UPDATE_FOOD_ITEM_IMAGE.replace("{id}", foodId.toString()),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      dispatch(setFoodId(foodId));
      dispatch(setLoading(false));
      dispatch(toastSuccess("Ushqimi u editua me sukses!"))
    } catch (error: any) {
      dispatch(
        setError(
          error.response?.data.message || "Error occurred while updating food"
        )
      );
      dispatch(setLoading(false));
    }
  };

const addNewFoodSlice = createSlice({
  name: "addNewFood",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFoodId: (state, action) => {
      state.foodId = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setFoodId, setError } = addNewFoodSlice.actions;

export default addNewFoodSlice.reducer;
