// Modal component (Modal.tsx)
import React from "react";

type ErrorProps = {
  message: any;
  onClose: () => void;
};

const ErrorModal: React.FC<ErrorProps> = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <div className="flex flex-col items-center pb-4">
          <h2 className="text-2xl font-medium">Warning</h2>
          <p className="mt-4">{message}</p>
        </div>
        <div className="flex justify-end">
          <button
            className="w-full px-4 py-2 mt-4 text-white rounded-md bg-customOrange"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
