import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL_CURRENT_RESTAURANT =
  "https://dev.api.jomuntu.com/api/v1/food-venues/restaurant-profile";

interface RestaurantState {
  id: number;
  name: string;
  emailAddress: string;
  phoneNumber: string;
  adminName: string;
  ownerName: string;
  ownerEmail: string;
  openingHours: string;
  location: string;
  category: string;
  logoUrl: string;
  loading: boolean;
  error: string | null;
}

const initialState: RestaurantState = {
  id: 0,
  name: "",
  emailAddress: "",
  phoneNumber: "",
  adminName: "",
  ownerName: "",
  ownerEmail: "",
  openingHours: "",
  location: "",
  category: "",
  logoUrl: "",
  loading: false,
  error: null,
};

// Async thunk for fetching restaurant profile
export const fetchRestaurantProfile = createAsyncThunk(
  "restaurant/fetchProfile",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(API_URL_CURRENT_RESTAURANT, {
        headers: {
            Authorization: `Bearer ${token}`,
          },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Something went wrong"
      );
    }
  }
);

export const updateRestaurant = createAsyncThunk(
  "restaurant/updateRestaurant",
  async ({ restaurant, imageFile }: { restaurant: RestaurantState; imageFile: File | null }, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    
    try {
      const restaurantResponse = await axios.put(
        `https://dev.api.jomuntu.com/api/v1/food-venues/restaurant-profile`,
        {
          name: restaurant.name,
          emailAddress: restaurant.emailAddress,
          phoneNumber: restaurant.phoneNumber,
          adminName: restaurant.adminName,
          ownerName: restaurant.ownerName,
          ownerEmail: restaurant.ownerEmail,
          openingHours: restaurant.openingHours,
          location: restaurant.location,
          category: restaurant.category
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (imageFile) {
        const formData = new FormData();
        formData.append("image", imageFile);

        await axios.put(
          `https://dev.api.jomuntu.com/api/v1/food-venues/${restaurant.id}/update-image`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
      return restaurantResponse.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Something went wrong");
    }
  }
);

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setProfile(state, action) {
      const profile = action.payload;
      state.id = profile.id;
      state.name = profile.name;
      state.emailAddress = profile.emailAddress;
      state.phoneNumber = profile.phoneNumber;
      state.adminName = profile.adminName;
      state.ownerName = profile.ownerName;
      state.ownerEmail = profile.ownerEmail;
      state.openingHours = profile.openingHours;
      state.category = profile.category
      state.location = profile.location;
      state.logoUrl = profile.logoUrl;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateRestaurant.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRestaurant.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const updatedRestaurant = action.payload;
        state.id = updatedRestaurant.id;
        state.name = updatedRestaurant.name;
        state.emailAddress = updatedRestaurant.emailAddress;
        state.phoneNumber = updatedRestaurant.phoneNumber;
        state.adminName = updatedRestaurant.adminName;
        state.ownerName = updatedRestaurant.ownerName;
        state.ownerEmail = updatedRestaurant.ownerEmail;
        state.openingHours = updatedRestaurant.openingHours;
        state.location = updatedRestaurant.location;
        state.category = updatedRestaurant.category;
        state.logoUrl = updatedRestaurant.logoUrl;
      })
      .addCase(updateRestaurant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setLoading, setError, setProfile } = restaurantSlice.actions;

export default restaurantSlice.reducer;
