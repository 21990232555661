import { Outlet } from 'react-router'
import SidebarRestaurant from '../../components/SidebarRestaurant/SidebarRestaurant'
import RestaurantHeader from '../../components/RestaurantHeader/RestaurantHeader'

type Props = {}

const RestuarantPanel = (props: Props) => {
  return (
    <div className="flex w-full min-h-screen">
      <SidebarRestaurant />
      <div className="flex flex-col items-center flex-grow bg-white">
        {/* HEADER */}
        <div className="h-[64px] w-full flex items-center justify-end">
          <RestaurantHeader />
        </div>
        <div className="w-11/12">
            <Outlet/>
        </div>
      </div>
    </div>
  )
}

export default RestuarantPanel