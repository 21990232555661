import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toastSuccess } from '../../utils/Toast/Toast';

interface ReviewRequestState {
  data: {
    id: number;
    imageUrl: string;
    foodName: string;
    restaurantName: string;
    categories: string[];
    price: number;
    contactNumber: string;
    location: string;
    message: string | null;
    status: string;
  }[];
  totalPages: number;
  page: number;
  loading: boolean;
  error: string | null;
}

const initialState: ReviewRequestState = {
  data: [],
  totalPages: 0,
  page: 0,
  loading: false,
  error: null,
};

export const fetchReviewRequestPosts = (page: number) => async (dispatch: any) => {
    dispatch(setLoading());
    try {
      const response = await axios.get(`https://dev.api.jomuntu.com/api/v1/food-items`, {
        params: {
          status: 'PENDING',
          page: page,
          size: 6,
        },
      });
      dispatch(setPosts({ ...response.data, page }));
    } catch (error: any) {
      dispatch(setError(error.response?.data?.message || 'Failed to fetch posts'));
    }
  };

  export const approveFoodItem = (foodItemId: number) => async (dispatch: any) => {
    try {
      dispatch(setLoading());
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authorization token is missing');
      }
  
      await axios.post(
        `https://dev.api.jomuntu.com/api/v1/food-items/${foodItemId}/approve`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(approveItem(foodItemId)); 
      dispatch(toastSuccess("Ushqimi u aprovua me sukses!"))
    } catch (error: any) {
      dispatch(setError(error.response?.data?.message));
    }
  };

  export const declineFoodItem = (foodItemId: number) => async (dispatch: any) => {
    try {
      dispatch(setLoading());
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authorization token is missing');
      }
  
      await axios.post(
        `https://dev.api.jomuntu.com/api/v1/food-items/${foodItemId}/decline`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      dispatch(declineItem(foodItemId));
      dispatch(toastSuccess("Ushqimi u refuzua me sukses!"))
    } catch (error: any) {
      dispatch(setError(error.response?.data?.message));
    }
  };
  
const reviewRequestSlice = createSlice({
  name: 'reviewRequest',
  initialState,
  reducers: {
    setLoading(state) {
      state.loading = true;
      state.error = null;
    },
    setError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setPosts(state, action) {
      state.loading = false;
      state.data = action.payload.content;
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.page;
    },
    resetPage(state) {
      state.page = 0;
    },
    approveItem(state, action) {
      state.data = state.data.filter((item) => item.id !== action.payload);
      state.loading = false; 
    },
    declineItem(state, action) {
      state.data = state.data.filter((item) => item.id !== action.payload);
      state.loading = false; 
    },
  },
});

export const { setLoading, setError, setPosts, resetPage, approveItem, declineItem } = reviewRequestSlice.actions;



export default reviewRequestSlice.reducer;
